.submissions-page {
    width: 100%;
    height: 100%;
}
.submissions-container {
    width: 35%;
    height: 90%;
    margin-left: 35%;
    margin-right: 35%;

    display: flex;
}

.submissions-header {
    width: 20%;
    height: 100%;

    box-sizing: border-box;

    writing-mode: vertical-lr;
    transform: rotate(180deg);

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 10vh;

    color: #D14160;
}

.submissions-note {
    width: 80%;
    height: 100%;
    margin-left: 5%;
    box-sizing: border-box;

    border: 2px solid purple;

    color: purple;

    padding: 5%;

    font-size: 1.3vw;
    font-family: 'forma-djr-display', sans-serif;
}