/* HomePage.css */
.homepage {
    position: relative;
    height: 100%;
    width: 100%;
  }
  
 
  
  .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 
  }
  
  .text {
    font-family: 'gyst-variable', sans-serif;
    color: #5B2A86;
    white-space: pre-line; 
    font-size: 2em; 
    text-align: left; 
  }
  
  .buttons {
    font-family: 'gyst-variable', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 1em; 
    align-items: flex-end;
  }
  
  .content2 {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    margin-top: 2em; 
  }
  
  .text2 {
    color: #5B2A86;
    font-size: 1em; 
    text-align: center; 
    margin-right: 1em; 
  }
  
  .expand-image {
    width: 4%; /* Adjust as needed */
    height: 4%; /* Adjust as needed */
  }
  
  .expand-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content-section {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  .zine-grid {
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    padding-top: 50px;

    top: 0;
    transition-property: position, top;
    transition-duration: 1s;
    transition-delay: 0.5s;


  }

  .zine-item {

    width: calc(70%/4);
    margin-right: calc(30%/3);

    height: 27em;

  }

  .zine-pic {
    height: 80%;
    width: 99%;

    background-color: gray;

    transition-property: transform, box-shadow;
    transition-duration: 0.5s;

  }
  .zine-pic img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .zine-caption {
    height: 20%;
    width: 100%;

    transition-property: opacity;
    transition-timing-function: ease-in-out;
    transition-duration: .5s;
    
  }
  
  .zine-pic-animation {

  }

  .zine-pic-animation:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5); /* Offset-x Offset-y Blur Spread Color */
    transform: translateY(-10px);
    transform: scale(1.05);

  }

  .zine-item:nth-of-type(4n) {
    margin-right: 0%;
  }

  .content-group {
    position: relative;
    opacity: 1;

    transition: opacity 0.5s;
  }

  .invisible {
    opacity: 0;
  }

  .slide-up {
    top: -40vh;
  }


 