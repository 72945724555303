.petal {
    transition: filter 0.3s ease; 
    
  }
  
  .flower-hover-area:hover ~ .petal {
    filter: brightness(1.5); 
  }
  .flower-hover-area {
    cursor: pointer; 
    
  }

  .bell-hover-area {
    position: absolute;
    cursor: pointer; 
    z-index: 5; 
    
  } 