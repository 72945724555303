.contactus-container {
    width: 60%;
    height: 55%;

    margin-left: 25%;
    margin-right: 25%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-heading {
    box-sizing: border-box;
    width: 70%;
    height: 40%;
    margin-bottom: 5%;

    display: flex;
    justify-content: center;
    align-items: flex-end;

    font-size: 6vw;
    color:#FABB64;
    border-bottom: 2px solid #FABB64;
   
    margin-left: 5%;
    margin-right: 5%;

}

.contact-info {
    box-sizing: border-box;
    width: 100%;
    height: 60%;

    border: 2px solid purple;

    display: flex;
    flex-direction: row;
    font-family: 'gyst-variable', sans-serif;
}

.insta {
    width: 40%;
    height: 100%;

    color: #5B2A86;

    border-right: 2px solid purple;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.email {
    height: 100%;
    width: 60%;

    color: #5B2A86;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.contact-pill {

    box-sizing: border-box;
    background-color: #FABB64;
    border: 2px solid purple;
    border-radius: 20px;
    font-size: 1.9vw;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;

}