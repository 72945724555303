.info {
    margin-left: 50%;
    margin-right: 10%;
    height: 50%;


    display: inline-block;
    margin-bottom: 10%;
    font-family: 'forma-djr-display', sans-serif;
}

.location-info {
    box-sizing: border-box;
    width: 90%;
    height: 60%;

    padding-left: 5%;
    padding-bottom:1em;
    line-height: 1em;

    border-bottom: 1px solid #5B2A86;
}

.specifics {
    width: 80%;
    height: 50%;

    padding-top: 5%;
    margin-left: 5%;
    margin-right: 5%;

    font-size: 1.1em;
    color: #5B2A86;
}

.print-issue-heading {
    width: 50%;
    height: 30%;

    margin-right: 50%;
}

.print-issue-container {
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;
}

.print-issue {
    box-sizing: border-box;
    width: 50%;
    height: 40%;
    margin-right: 50%;

    border: 2px #5B2A86 solid;
    border-radius: 20px;

    font-size: 3em;
    font-weight: 300;
    color:#5B2A86;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.information {

    box-sizing: border-box;
    width: 100%;
    height: 60%;

    font-size: 4em;
    margin: 0;

    color: #5B2A86;
}