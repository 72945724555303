.about-container {
    width: 60%;
    height: 90%;

    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-banner {
    font-family: 'gyst-variable', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 10%;

    border-radius: 15px;

    background-color: #FABB64;
    color: #5B2A86;
    border: 2px #5B2A86 solid;

    font-size: 2em;
    margin-bottom: 5%;
}

.team-div {
    height: 80%;
    width: 100%;

    border: 2px solid #470689;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    flex-wrap: wrap;

    padding: 2%;;
}

.team-box {
    width: 50%;
    height: 50%;
    box-sizing: border-box;

    position: relative;
}

.right-dir {

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    padding-right: 5%;
}

.left-dir {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    padding-left: 5%;
}

.name-div {
    font-size: 2em;

    border: 2px solid;

    border-radius: 15px;
    box-sizing: border-box;

    padding: 10px 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'gyst-variable', sans-serif;

}

.role {
    font-size: 1.2em;
    font-family: 'gyst-variable', sans-serif;
    
}

.invisible {
    opacity: 0;
}

.display-box {
    transition: 1s opacity;
}

.messages-box {
    position: absolute;
    transition: 1s opacity;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    left: 0;
    top: 0;
}


.name-role-div {
    height: 18%;
    width: 100%;

    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 2%;
    
    box-sizing: border-box;

    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'gyst-variable', sans-serif;
}

.paragraph-div {
    height: 80%;
    width: 100%;
    overflow: hidden;

    font-size: 12px;
    color: #5B2A86;

    padding-left: 5%;
    padding-right: 5%;
    box-sizing: border-box;
    font-family: 'forma-djr-display', sans-serif;
}