@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.App {
  min-width: 1346px;
  min-height: 732px;
  overflow: auto;
}

.navbar {
  animation: slideDown 0.5s ease-out forwards;
}

.root {
  box-sizing: border-box;
  overflow-y: auto;
  margin-left: 3vw;
  margin-right: 3vw;
  padding-top: 2em;
  padding-left: 2em;
  padding-right: 2em;
  overflow-y: auto;
  overflow-x: auto;
  height: 78vh;
}

.bottom {
  position: fixed;
  width: 100%;
  height: 10vh;

  bottom:0;
  left:0;

  border-top: 2px solid #5B2A86;
  background-color: #FEF7EF;
}


.vertical-line-left {
  position: fixed;
  left: 3vw;
  top: 0;
  bottom: 0;
  width: 2px; /* Adjust the width of the line as needed */
  background-color: #5B2A86; /* Adjust the color of the line as needed */
  content: '';
}

.vertical-line-right {
  position: fixed;
  right: 3vw;
  top: 0;
  bottom: 0;
  width: 2px; /* Adjust the width of the line as needed */
  background-color: #5B2A86; /* Adjust the color of the line as needed */
  content: ''; 
}

