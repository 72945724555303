.zine-page {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    width: 100%;
    height: 100%;
}

.carousel-container {
   width: 50%;
   margin-right: 20%;
   height: 100%;

   display: flex;
   flex-direction: column;
}

.index-container {
    width: 30%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.index-container li {
    color: #D14160;
}

.zine-carousel {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: fit-content;
    height: 80%;

}

.zine-image {
   width: 100%;
   height: 100%; 

    flex-shrink: 0;
    flex-grow: 0;

    transition: transform 1s;
}

.left-arrow {
    width: 5%;

}

.carousel {
    height: 100%;
    /*height: calc(80% * 11 / 8); */
    aspect-ratio: 11/8;

    display: flex;
    overflow: hidden;
}

.right-arrow {
    width: 5%;
    transform: scaleX(-1);
}

.artist-index {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    width: 100%;
    height: 15%;
    margin-left: 5%;


}

.index {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    width: 50%;
}

.index p {
    margin-bottom: 0em;
    margin-top: 5px;
}

.right-align {
    text-align: right;
}

li {
    transition: color 200ms;
}

li:hover {
    color: #5B2A86;

    cursor: pointer;
}
