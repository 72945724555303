.ZineModal-wrapper{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.ZineModal-backdrop{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, .55);
}

.ZineModal-box{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-width: 45%;
    width: fit-content;
    height: 95%;
    overflow-y: auto;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    z-index: 110;

}
